import React, { useEffect, useRef, useState } from 'react'
import Wallet from './Wallet';
// import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

export default function PhantomModal(props) {
    const [nftCollection, setNftCollection] = useState(null);
    const [error, setError] = useState(false);
    const modalRef = useRef();

    useEffect(() => {
        window.addEventListener('mousedown', pageClick, false);

        return () => {
        };
    }, [])

    const pageClick = (e) => {
        if (modalRef && modalRef.current && !modalRef.current.contains(e.target)) {
            closeModal()
        }
    }

    const closeModal = () => {
        props.closeModal && props.closeModal()
    }

    const renderNFTModal = () => {
        return (
            <div className='modal-overlay'>
                <div className='modal-container phantom' style={{fontFamily: 'Montserrat'}} ref={modalRef}>
                    <h3 style={{ margin: 0 }}>Exchange your NFTs</h3>
                    <div style={{display: window.innerWidth >= 517 ? 'flex' : undefined, justifyContent: 'space-between', alignContent: 'center', marginBottom: '1rem'}}>
                        <p style={{ margin: 'auto 0', fontSize: '12px' }}>Click on the Los Angeles NFT you want to exchange </p>
                        {props.WalletMultiButton}
                    </div>
                    
                    <Wallet setNftCollection={handleSetNftCollection} reload={props.reload} isPublic={true}/>
                    {renderNFTGallery()}
                </div>
            </div>
        )
    }

    const handleSetNftCollection = (nfts) => {
        setNftCollection(nfts?.length ? nfts : null)
    }

    const renderNFTGallery = () => {
        if (nftCollection?.length) {
            return (
                <div className='collection-modal' style={{ padding: 0 }}>

                    <div className='gallery-container'>
                        {
                            nftCollection.map((element, index) => {
                                return renderGalleryElement(element, index)
                            })
                        }
                    </div>
                </div>
            )
        }
    }

    const renderGalleryElement = (element, index, placeholder) => {
        return (
            <div className='element' style={{ backgroundImage: `url(${element?.thumbnail || element?.profilePicture || element?.imageUrl || placeholder})`, animationDuration: index / 10 + 's' }} key={element?.tokenAddress} onClick={() => exchangeNFT(element)}>
                <div className='title-card'>{element?.name || element?.userName}</div>
                <div className='title-card exchange'>Click to exchange</div>
                {
                    element?.size && element?.size !== '' &&
                    <div className='badge'>
                        {element?.size}
                    </div>
                }
            </div>
        )
    }

    const exchangeNFT = (nft) => {
        if (!nft)
            return

        props.handleReplaceNft(nft?.tokenAddress)
    }

    return renderNFTModal()
}
