export default function Navbar(props) {
     
    const renderMobileVersion = () => {
        return (
            <div className='header-container'>
                <div className='mobile-navbar-user'>
                    <a href={'https://verseworld.com/map/free-navigation'} className='logo-container'>
                        <div className='logo' />
                    </a>
                </div>
            </div>
        )
    }

    const renderHeader = () => {
        return (
            <div className='header-container'>
                <div className='navbar-user' style={{ opacity: props.opacity }}>
                    <a href={'https://verseworld.com/map/free-navigation'}  to={'/map/free-navigation'} className='element'>
                        <img src={'/logo.png'} className='logo' />
                    </a>
    
                    <a href={'https://verseworld.com/map/free-navigation'} to={'/map/free-navigation'} className='element'>
                        <div className={'button ' + (props.pageTitle === 'VERSE' ? 'active' : '')}>
                            <i class='bx bxs-map-alt' />
                            <div>
                                Verse World
                            </div>
                        </div>
                    </a>
    
                    <a href={'https://verseworld.com/download'} to={'/download'} className='element'>
                        <div className={'button ' + (props.pageTitle === 'DOWNLOAD' ? 'active' : '')}>
                            <i class='bx bx-download'></i>
                            <div>
                                Join The Dreamers
                            </div>
                        </div>
                    </a>
    
                    <div className='element'>
                        <div className={'button phantom'} />
                    </div>
    
                    <a href={'https://verseworld.com/start/login'} to={'/start/login'} className='element'>
                        <div className='profile active login' style={{ backgroundImage: 'url(/login-icon.png)' }}>
                        </div>
                    </a>
                </div>
            </div>
        )
    }

    if (window.innerWidth <= 768)
        return renderMobileVersion()
    return renderHeader()

}
